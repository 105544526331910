import { forwardRef } from 'react';
import styled from 'styled-components';

const FloatingButton = styled.button<{ bgColor: string }>`
  position: fixed;
  border: transparent;
  bottom: 78px;
  right: 84px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const ButtonImage = styled.img`
  width: 40px;
  height: auto;
`;

const FloatingChatButton = forwardRef<
  HTMLButtonElement,
  {
    isDisabled: boolean;
    onClick: () => void;
    bgColor: string;
    botIconUrl: string;
  }
>(({ isDisabled, onClick, bgColor, botIconUrl }, ref) => {
  return (
    <FloatingButton
      ref={ref}
      bgColor={bgColor}
      disabled={isDisabled}
      onClick={onClick}
    >
      <ButtonImage
        src={
          botIconUrl ||
          'https://f004.backblazeb2.com/file/skygram-utils/logo/skygram-logo-white.png'
        }
        alt="logo"
        width="32"
        height="32"
      />
    </FloatingButton>
  );
});

export default FloatingChatButton;
