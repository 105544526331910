import { ReactNode } from 'react';
import styled from 'styled-components';
const SkygramEmbed = styled.div`
  z-index: 10000;
`;

export default function ChatRequestContainer({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <SkygramEmbed
      className="skygram-embed"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Talk with us"
    >
      {children}
    </SkygramEmbed>
  );
}
