import '@livekit/components-styles';
import generateUniqueId from 'generate-unique-id';
import { Room } from 'livekit-client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { REACT_APP_LIVEKIT_SOCKET_URL } from '../constant';
import { MetaProps } from '../types';
import { connectAgent, connectPublicRoom } from '../util';
import { IAccount } from '../util/interface/IAccount.interface';
import ChatRequest from './ChatRequest';

const socketUrl = REACT_APP_LIVEKIT_SOCKET_URL;

export default function ChatBot({
  apiKey,
  meta,
}: {
  apiKey: string;
  meta?: MetaProps;
}) {
  const [token, setToken] = useState<string | undefined>();
  const roomRef = useRef<Room>(new Room());
  const [uniqueId, setUniqueId] = useState<string>('');
  const [account, setAccount] = useState<IAccount | undefined>();

  const getStatus = useCallback(async () => {
    const account = await connectPublicRoom(apiKey);
    setAccount(account);
  }, [apiKey]);

  useEffect(() => {
    getStatus();
    if (!meta?.id) {
      setUniqueId(
        generateUniqueId({
          length: 10,
          useLetters: true,
          useNumbers: false,
        })
      );
    }
  }, [getStatus, meta?.id]);

  useEffect(() => {
    const existingSessionId = sessionStorage.getItem('uniqueSessionId');
    if (!existingSessionId) {
      const newSessionId = generateUniqueId({
        length: 16,
        useLetters: true,
        useNumbers: true,
      });
      sessionStorage.setItem('uniqueSessionId', newSessionId);
    }
  }, []);

  const connectWithPublicRoom = useCallback(async () => {
    try {
      const uniqueSessionId = sessionStorage.getItem('uniqueSessionId');
      if (uniqueSessionId) {
        const resData = await connectAgent({
          participantName: uniqueSessionId,
          roomName: `${apiKey}_public_room`,
        });
        if (resData?.length) {
          if (socketUrl && resData) {
            setToken(resData);
            await roomRef.current.connect(socketUrl, resData);
          }
        }
      }
    } catch (error) {
      console.error('Error connecting to LiveKit:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, meta?.id, uniqueId]);

  useEffect(() => {
    connectWithPublicRoom();

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      roomRef.current.disconnect();
    };
  }, [connectWithPublicRoom]);

  return token ? (
    <ChatRequest
      room={roomRef.current}
      meta={meta}
      uniqueId={uniqueId}
      account={account}
    />
  ) : null;
}
