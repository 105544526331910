/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RecordPlugin } from '@rrweb/types';

interface NetworkEvent {
  type: string;
  method?: string;
  url?: string | URL | undefined;
  status?: number;
  duration?: string;
  requestSize?: number;
  responseSize?: number;
  requestHeaders?: any;
  responseHeaders?: any;
  curl?: string;
}

function generateCurlCommand(log: any): string {
  const { method, url, headers, body } = log;
  let curlCommand = `curl -X ${method} ${url}`;

  if (headers && typeof headers === 'object') {
    for (const [header, value] of Object.entries(headers)) {
      curlCommand += ` -H "${header}: ${value}"`;
    }
  }

  if (body) {
    curlCommand += ` -d '${body}'`;
  }

  return curlCommand;
}

export function getRecordNetworkPlugin(): RecordPlugin {
  const networkBuffer: NetworkEvent[] = [];

  return {
    name: 'rrweb/network',
    observer(cb) {
      const originalXhrOpen = XMLHttpRequest.prototype.open;
      const originalXhrSetRequestHeader =
        XMLHttpRequest.prototype.setRequestHeader;
      const originalXhrSend = XMLHttpRequest.prototype.send;

      const originalFetch = window.fetch;

      XMLHttpRequest.prototype.open = function (
        method: string,
        url: string | URL,
        async: boolean = true,
        username?: string | null,
        password?: string | null
      ) {
        (this as any).logData = {
          method,
          url,
        };
        (this as any).requestHeaders = {};
        originalXhrOpen.apply(this, [method, url, async, username, password]);
      };

      XMLHttpRequest.prototype.setRequestHeader = function (
        header: string,
        value: string
      ) {
        (this as any).requestHeaders![header] = value;
        originalXhrSetRequestHeader.apply(this, [header, value]);
      };

      XMLHttpRequest.prototype.send = function (...args) {
        const start = performance.now();
        this.addEventListener('loadend', function () {
          const duration = performance.now() - start;
          const log: NetworkEvent = {
            type: 'XMLHttpRequest',
            url: (this as any).logData?.url,
            method: (this as any).logData?.method,
            status: this.status,
            duration: `${duration.toFixed(2)}ms`,
            requestSize: (args[0] as any)?.length || 0,
            responseSize: this.responseText.length,
            requestHeaders:
              (this as any).requestHeaders || 'No request headers',
            responseHeaders:
              this.getAllResponseHeaders() || 'No response headers',
            curl: generateCurlCommand({
              type: 'XMLHttpRequest',
              timestamp: (this as any).logData?.timestamp,
              method: (this as any).logData?.method,
              url: (this as any).logData?.url,
              headers: (this as any).requestHeaders,
              body: args[0],
            }),
          };
          networkBuffer.push(log);
          cb(log); // Use callback to handle log data
        });
        originalXhrSend.apply(this, args);
      };

      window.fetch = function (...args) {
        const start = performance.now();
        return originalFetch.apply(this, args).then((response) => {
          const duration = performance.now() - start;
          return response
            .clone()
            .text()
            .then((body) => {
              const log: NetworkEvent = {
                type: 'Fetch',
                url: args[0] as string | URL | undefined,
                method: args[1]?.method || 'GET',
                status: response.status,
                duration: `${duration.toFixed(2)}ms`,
                requestSize: (args[1]?.body as any)?.length || 0,
                responseSize: body.length,
                requestHeaders: args[1]?.headers || 'No request headers',
                responseHeaders: response.headers || 'No response headers',
                curl: generateCurlCommand({
                  type: 'Fetch',
                  method: args[1]?.method || 'GET',
                  url: args[0],
                  headers: args[1]?.headers || {},
                  body: args[1]?.body,
                }),
              };
              networkBuffer.push(log);
              cb(log); // Use callback to handle log data
              return response;
            });
        });
      };

      // Return a cleanup function to restore original methods
      return () => {
        XMLHttpRequest.prototype.open = originalXhrOpen;
        XMLHttpRequest.prototype.setRequestHeader = originalXhrSetRequestHeader;
        XMLHttpRequest.prototype.send = originalXhrSend;
        window.fetch = originalFetch;
      };
    },
    options() {},
  };
}
