import { get, post, put } from 'coducer-fetch';
import {
  REACT_APP_LIVEKIT_BASIC_URL,
  REACT_APP_USER_BASIC_URL,
} from '../../constant';

export async function validateAccount(apiKey: string) {
  const response = await post(
    `${REACT_APP_USER_BASIC_URL}/accounts/validate/${apiKey}`,
    {}
  );
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return { success: false, message: res?.message };
    }
  }
}

export async function createParticipant(params: {
  participantName: string;
  roomName: string;
}) {
  const response = await post(
    `${REACT_APP_LIVEKIT_BASIC_URL}/api/livekit/v1/participant`,
    params
  );
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return res;
    }
  }
}

export async function updateParticipantMeta(params: {
  roomName: string;
  participantIdentity: string;
  metaData: string;
}) {
  const response = await put(
    `${REACT_APP_LIVEKIT_BASIC_URL}/api/livekit/v1/updateMetaData`,
    params
  );
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return res;
    }
  }
}

export async function getMetaDetailsFormMachine(params: {
  lat: string;
  lon: string;
}) {
  const response = await get(
    `${REACT_APP_LIVEKIT_BASIC_URL}/api/location`,
    params
  );
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return res;
    }
  }
}

export async function connectWithAgent(params: {
  sessionId: string;
  roomName: string;
}) {
  const response = await post(
    `${REACT_APP_LIVEKIT_BASIC_URL}/api/livekit/v1/connectWithAgent`,
    params
  );
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return res;
    }
  }
}

export async function sendMessage(params: {
  roomName: string;
  message: string;
  destinationIdentities: [string];
  topic: string;
  extraMessage?: string;
}) {
  const response = await post(
    `${REACT_APP_LIVEKIT_BASIC_URL}/api/livekit/v1/sendMessage`,
    params
  );
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return res;
    }
  }
}

export async function createEnduserSession(params: {
  accountId: string;
  customer_uuid: string;
  customer_meta: string;
  call_type: string;
  can_replay: boolean;
}) {
  const response = await post(
    `${REACT_APP_USER_BASIC_URL}/sessions/enduser_session`,
    params
  );
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return res;
    }
  }
}

export async function getSession(id: string, apiKey: string) {
  const response = await fetch(`${REACT_APP_USER_BASIC_URL}/sessions/${id}`, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
      "api_key": apiKey
    }
  })
  if (response) {
    const res = await response.json();
    if (response.ok) {
      return res;
    } else {
      return res;
    }
  }
}
