/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const AgentConnectTitleText = styled.p`
  color: #a8a7a7;
  font-size: 12px;
  font-family: 'Fira Sans', sans-serif;
  padding: 0;
  text-align: center;
`;

function CallTimer({ updatedTime }: { updatedTime: any }) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  useEffect(() => {
    const now = Date.now();
    const storedStartTime = updatedTime
      ? updatedTime
      : localStorage.getItem('callStartTime');

    if (storedStartTime) {
      const elapsedTime = Math.floor((now - Number(storedStartTime)) / 1000);
      setHours(Math.floor(elapsedTime / 3600));
      setMinutes(Math.floor((elapsedTime % 3600) / 60));
      setSeconds(elapsedTime % 60);
    } else {
      localStorage.setItem('callStartTime', String(now));
    }

    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          setMinutes((prevMinutes) => {
            if (prevMinutes === 59) {
              setHours((prevHours) => prevHours + 1);
              return 0;
            }
            return prevMinutes + 1;
          });
          return 0;
        } else {
          return prevSeconds + 1;
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [updatedTime]);

  useEffect(() => {
    const now = Date.now();
    localStorage.setItem(
      'callStartTime',
      String(now - (hours * 3600 + minutes * 60 + seconds) * 1000)
    );
  }, [seconds, minutes, hours]);

  const formatTime = (num: any) => (num < 10 ? `0${num}` : num);

  return (
    <AgentConnectTitleText>
      {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
    </AgentConnectTitleText>
  );
}

export default CallTimer;
