import { CiStreamOn } from 'react-icons/ci';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InnerContent = styled.div`
  display: flex;
  height: 100%;
`;

export const ContentArea = styled.div<{ isChatOpen: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ isChatOpen }) => (isChatOpen ? '66.6667%' : '100%')};
`;

export const ChatArea = styled.div<{ isChatOpen: boolean }>`
  display: ${({ isChatOpen }) => (isChatOpen ? 'block' : 'none')};
  width: 33.3333%;
  background-color: #fafafa;
  height: 100%;
  padding: 0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const ControlBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.75rem;
  max-height: 100px;
  padding: 0;
`;

export const StyledButton = styled.button<{
  variant: 'primary' | 'success' | 'danger';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return '#007bff';
      case 'success':
        return '#28a745';
      case 'danger':
        return '#dc3545';
      default:
        return '#007bff';
    }
  }};
  color: white;
  cursor: pointer;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
`;

export const TimerText = styled.p`
  color: #ffc107;
  display: flex;
  align-items: center;
`;

export const LoadingText = styled.div`
  font-size: 0.8rem;
  font-weight: 800;
  color: #007bff;
  margin-right: 10px;
  text-align: center;
`;

export const CenteredText = styled.div`
  text-align: center;
  font-family: 'Fira Sans', sans-serif;
`;

export const AgentNameText = styled.span`
  font-weight: bold;
`;

export const ChatIcon = styled.button<{ isChatOpen: boolean }>`
  position: relative;
  padding: 6px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  background-color: ${({ isChatOpen }) => (isChatOpen ? '#007bff' : '#c5c5c5')};

  & .badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    font-size: 0.75rem;
    padding: 0.3rem 0.5rem;
    background-color: #2f55d4;
    color: white;
  }
`;

export const BlinkingIcon = styled(CiStreamOn)`
  color: red;
  animation: blink-animation 1.5s infinite;
  @keyframes blink-animation {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;

export const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #000000;
  font-family: 'Fira Sans', sans-serif;
`;

export const SubTitleCoBrowse = styled.div`
  font-size: 0.9rem;
  font-weight: 350;
  color: #000000;
  font-family: 'Fira Sans', sans-serif;
`;

export const InnerShadow = styled.div`
  background-color: #fafafa;
  border-radius: 0;
`;

export const InvisibleScrollbar = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CarouselLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ControllBarWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const CarouselContainer = styled.div`
  display: flex;
  padding: 0 0.5rem;
  padding-bottom: 0;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
`;

export const Heading = styled.h6`
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
`;

export const FlexColumnContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`;
