import { ChatMessage, ReceivedChatMessage } from '@livekit/components-react';
import { Room } from 'livekit-client';
import { useEffect, useRef, useState } from 'react';
import ChatBody from './styleComponent/Chat/chatBody';
import ChatContainer from './styleComponent/Chat/chatContainer';
import ChatMessageList from './styleComponent/Chat/chatMessageList';
import MessageInput from './styleComponent/Chat/messageInput';
import SendButton from './styleComponent/Chat/sendButton';

export default function Chat({
  chat,
  room,
  messageList,
  setMessageList,
}: {
  chat: {
    send: ((message: string) => Promise<ChatMessage>) | undefined;
    update:
      | ((message: string, messageId: string) => Promise<ChatMessage>)
      | undefined;
    chatMessages: ReceivedChatMessage[];
    isSending: boolean;
  };
  room: Room;
  messageList: ReceivedChatMessage[];
  setMessageList: (data: ReceivedChatMessage[]) => void;
}) {
  const [message, setMessage] = useState<string>('');
  const chatBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMessageList(chat?.chatMessages);
  }, [chat?.chatMessages, setMessageList]);

  useEffect(() => {
    chatBodyRef.current?.scrollTo({
      top: chatBodyRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messageList]);

  const sendMessage = () => {
    if (message.trim() === '') return;

    // @ts-expect-error TODO: Fix me
    chat?.send(message)?.then(() => {
      setMessage('');
      chatBodyRef.current?.scrollTo({
        top: chatBodyRef.current.scrollHeight,
        behavior: 'smooth',
      });
    });
  };

  return (
    <ChatContainer>
      <ChatBody ref={chatBodyRef}>
        {messageList?.map((item) => (
          <ChatMessageList item={item} room={room} />
        ))}
      </ChatBody>
      <MessageInput
        message={message}
        setMessage={setMessage}
        sendMessage={sendMessage}
      >
        <SendButton chat={chat} message={message} sendMessage={sendMessage} />
      </MessageInput>
    </ChatContainer>
  );
}
