import { LiveKitService } from '../service';

export const connectPublicRoom = async (apiKey: string) => {
  const response = await LiveKitService.validateAccount(apiKey);
  if (response?.success) {
    return response?.account;
  } else {
    console.error(response?.message);
    return undefined;
  }
};
interface ParticipantResponse {
  success: boolean;
  token: string;
}

export const connectAgent = async (params: {
  participantName: string;
  roomName: string;
}): Promise<string | undefined> => {
  const response: ParticipantResponse =
    await LiveKitService.createParticipant(params);

  if (response?.success) {
    return response?.token;
  }
};

export const ToHexColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = (hash & 0x00ffffff).toString(16).toUpperCase();
  return '#' + '00000'.substring(0, 6 - color.length) + color;
};
