import styled from 'styled-components';
import { HiMiniChatBubbleLeftRight } from 'react-icons/hi2';
import { ReactNode } from 'react';

const Container = styled.div`
  cursor: ns-resize;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  background-color: white;
  color: black;
  font-weight: bold;
`;

export default function ChatContainer({ children }: { children: ReactNode }) {
  return (
    <Container>
      <Header>
        <HiMiniChatBubbleLeftRight size={20} />
        <>Chat</>
      </Header>
      {children}
    </Container>
  );
}
