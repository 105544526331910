/* eslint-disable @typescript-eslint/no-explicit-any */
import { LiveKitRoom, useRemoteParticipants } from '@livekit/components-react';
import generateUniqueId from 'generate-unique-id';
import { Howl } from 'howler';
import {
  ConnectionState,
  DataPacket_Kind,
  RemoteParticipant,
  Room,
  RoomEvent,
} from 'livekit-client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HiPhoneMissedCall } from 'react-icons/hi';
import { MdOutlineTimer } from 'react-icons/md';
import {
  REACT_APP_CALL_JOIN_TIMEOUT_SEC,
  REACT_APP_LIVEKIT_SOCKET_URL,
} from '../constant';
import { LiveKitService } from '../service';
import { MetaProps } from '../types';
import { connectAgent } from '../util';
import { encrypt } from '../util/enCryptAndDeCrypt';
import { IAccount } from '../util/interface/IAccount.interface';
import CountDown from './CountDown';
import { DraggablePopoverRef } from './DraggablePopover';
import PrivateRoom from './privateRoom';
import {
  ActionButton,
  AgentConnectTitle,
  ButtonWrapper,
  CallingHeader,
  CobrowsingImage,
  CobrowsingInfo,
  CobrowsingText,
  ColumnContainer,
  Container,
  ErrorText,
  FlexColumnDiv,
  FlexContainer,
  JoinContainer,
  Logo,
  LogoContainer,
  LogoText,
  StyledButton,
  Subtitle,
  SuccessText,
  Title,
  WarningText,
} from './styleComponent/ChatRequest/ChatBotPopover';
import ChatDraggablePopover from './styleComponent/ChatRequest/ChatDraggablePopover';
import FloatingChatButton from './styleComponent/ChatRequest/FloatingButton';
import ChatRequestContainer from './styleComponent/ChatRequest/SkygramEmbed';

const socketUrl = REACT_APP_LIVEKIT_SOCKET_URL;
export default function ChatRequest({
  room,
  meta,
  uniqueId,
  account,
}: {
  room: Room;
  meta?: MetaProps;
  uniqueId?: string;
  account: IAccount | undefined;
}) {
  const [updatedMeta, setMeta] = useState<string | undefined>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const userLatitude = useRef<number | null>(null);
  const userLongitude = useRef<number | null>(null);
  const [isActive, setActive] = useState(false);
  const [actionType, setActionType] = useState<
    'Reject' | 'Join' | 'Request' | 'Accept' | 'Cancel' | undefined
  >();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [isConnectAgent, setIsConnectAgent] = useState<boolean>(true);
  const [privateToken, setPrivateToken] = useState<string | undefined>();
  const [privateRoomId, setPrivateRoomId] = useState<string | undefined>();
  const [isPrivateRoomJoined, setPrivateRoomIdJoined] =
    useState<boolean>(false);
  const [senderName, setSenderName] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const floatingChatBtnRef = useRef<HTMLButtonElement>(null);
  const draggablePopoverRef = useRef<DraggablePopoverRef>(null);
  const sound = new Howl({
    src: [
      'https://skygram-utils.s3.us-west-004.backblazeb2.com/PhoneCallingSoundEffect.mp3',
    ], // Provide the sound file path
  });
  let rejectTimeout: NodeJS.Timeout | undefined;
  const callIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isCallActive, setIsCallActive] = useState(false);
  const connectingAgentName = useRef<string | undefined>(undefined);
  const JoinTimeoutRef = useRef<number | undefined>();
  const [isOfflineString, setIsOfflineString] = useState<string>('');

  const remoteParticipants: RemoteParticipant[] = useRemoteParticipants({
    updateOnlyOn: [
      RoomEvent.ParticipantConnected,
      RoomEvent.ParticipantDisconnected,
      RoomEvent.Connected,
      RoomEvent.Disconnected,
      RoomEvent.Reconnected,
    ],
    room,
  });
  useEffect(() => {
    const getPrivateRoomId = sessionStorage.getItem('privateRoomId');
    if (getPrivateRoomId && isActive) {
      setShowOverlay(true);
      setPrivateRoomIdJoined(true);
      setPrivateRoomId(getPrivateRoomId);
      joinRoom(getPrivateRoomId);
    }
    const getIncomingSessionId = sessionStorage.getItem('incomingSessionId');
    if (getIncomingSessionId && isActive) {
      setShowOverlay(true);
      setPrivateRoomId(getIncomingSessionId);
      setActionType('Join');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (actionType) {
      setShowOverlay(true);
      if (actionType === 'Join') {
        sound.play();
      }
    }
    if (actionType === 'Cancel') {
      sound.stop();
      setActionType(undefined);
      setError('Agent canceled the call');
      sessionStorage.removeItem('incomingSessionId');
    }
    return () => {
      sound.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType]);

  const sendMessage = useCallback(
    async (
      topic: 'Reject' | 'Join' | 'Request' | 'Cancel',
      name?: string,
      sessionId?: string
    ) => {
      const currentName = name || senderName || '';
      if (currentName) {
        await LiveKitService.sendMessage({
          roomName: privateRoomId || room?.name,
          message: `${sessionId || privateRoomId || room?.name}-${
            room.localParticipant.identity
          }`,
          destinationIdentities: [currentName],
          topic: topic,
          extraMessage: meta?.name,
        });
        resetConnectionState(topic === 'Request');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [privateRoomId, room.localParticipant.identity, room?.name, senderName]
  );

  const handleRejectRequest = useCallback(async () => {
    setIsCallActive(false);
    sessionStorage.removeItem('incomingSessionId');
    setActionType(undefined);
    if (rejectTimeout) {
      clearInterval(rejectTimeout);
    }
    if (JoinTimeoutRef) {
      JoinTimeoutRef.current = undefined;
    }
    await sendMessage('Reject');
    hidePopOver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessage]);

  const getActionTry = (action: string) => {
    switch (true) {
      case action.includes('Reject'):
        return 'Reject';
      case action.includes('Join'):
        return 'Join';
      case action.includes('Accept'):
        return 'Accept';
      case action.includes('Cancel'):
        return 'Cancel';
      default:
        return undefined;
    }
  };

  //decode the recived data
  const handleDataReceived = useCallback(
    (
      payload: Uint8Array,
      participant?: RemoteParticipant,
      kind?: DataPacket_Kind | undefined,
      topic?: string | undefined
    ) => {
      const decoder = new TextDecoder();
      if (topic === 'Error') {
        console.info('received error message:', decoder.decode(payload));
        setError(decoder.decode(payload));
        return;
      }
      const decodedMessage = decoder.decode(payload).split('-');

      let extraMessage: string | undefined;
      if (decodedMessage.length > 2) {
        extraMessage = decodedMessage[decodedMessage.length - 1];
      }
      const privateRoomId = decodedMessage[0];
      const senderName = decodedMessage[1];
      console.info(
        `Decoded: ${decoder.decode(
          payload
        )}, \n Topic: ${topic}, Kind: ${kind}, Participant: ${participant}, Sender: ${senderName}, Extra: ${extraMessage}`
      );

      if (topic === 'Send') {
        if (extraMessage && !isNaN(Number(extraMessage))) {
          JoinTimeoutRef.current = parseInt(extraMessage, 10);
        } else {
          console.info('extraMessage is not a valid number its a string');
        }
      }

      if (privateRoomId && topic === 'Join') {
        sound.play();
        sessionStorage.setItem('incomingSessionId', privateRoomId);
        setPrivateRoomId(privateRoomId);
      }
      setSenderName(senderName);
      // if (topic === 'Reject' || topic === 'Skipped') {
      //   if (callIntervalRef.current) {
      //     console.log('check clear interval on skip');
      //     clearInterval(callIntervalRef.current);
      //   }
      //   console.log('check call agent directly on reject or skip');
      //   getAgentName(privateRoomId);

      //   callIntervalRef.current = setInterval(() => {
      //     console.log('check add interval on the reject or skipped ');
      //     getAgentName(privateRoomId);
      //   }, 10000);
      // }
      topic && topic != 'Send' && setActionType(getActionTry(topic));
      if (topic === 'Accept') {
        joinRoom(privateRoomId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [room]
  );

  useEffect(() => {
    room.on(RoomEvent.DataReceived, handleDataReceived);
    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived);
    };
  }, [handleDataReceived, room]);

  const getAgentName = useCallback(
    async (sessionId: string) => {
      const getAgentResponse = await LiveKitService.connectWithAgent({
        sessionId: sessionId,
        roomName: room.name,
      });
      console.info('getAgentResponse', getAgentResponse);
      console.info('data.agentName', getAgentResponse?.data?.agentName);

      if (!getAgentResponse?.success) {
        setError(getAgentResponse?.message);
        setIsCallActive(false);
        setActionType(undefined);
        setIsConnectAgent(true);
        if (callIntervalRef.current) {
          // console.log('check clear interval on error');
          clearInterval(callIntervalRef.current);
        }
      } else {
        // console.log('remoteParticipants inside check', remoteParticipants);
        const agentAvailable = remoteParticipants.some((participant) =>
          participant.identity.includes(getAgentResponse?.data?.agentName)
        );
        // console.log('agentAvailable status', agentAvailable);

        if (!agentAvailable) {
          // console.info('Agent not available, getting new agent');
          getAgentName(sessionId);
        } else {
          // console.info('sending message to Agent');
          connectingAgentName.current = getAgentResponse?.data?.agentName;
          await sendMessage(
            'Request',
            `${getAgentResponse?.data?.agentName}(Agent)`,
            sessionId
          );

          setIsCallActive(true);
        }
      }
    },
    [room.name, sendMessage, remoteParticipants]
  );

  const sendRequest = useCallback(async () => {
    setIsConnectAgent(false);
    if (updatedMeta && account && meta) {
      const sessionCreationPayload = {
        accountId: account?.id,
        customer_uuid: room.localParticipant.identity,
        customer_meta: updatedMeta,
        customer_name: meta?.name || '',
        customer_email: meta?.email || '',
        call_type: 'INCOMING',
        can_replay: true,
      };

      const sessionRes = await LiveKitService.createEnduserSession(
        sessionCreationPayload
      );
      if (sessionRes?.id) {
        // console.log('check call agent directly');
        getAgentName(sessionRes?.id);
        callIntervalRef.current = setInterval(() => {
          // console.log('check add interval on initial');
          getAgentName(sessionRes.id);
        }, 10000);
      } else {
        setError(sessionRes?.message);
      }
    }
  }, [
    account,
    getAgentName,
    meta,
    room.localParticipant.identity,
    updatedMeta,
  ]);

  const resetConnectionState = (isRequest?: boolean) => {
    if (!isRequest) {
      setIsConnectAgent(true);
    }
    if (actionType !== 'Join') {
      setActionType(undefined);
      if (rejectTimeout) {
        clearInterval(rejectTimeout);
      }
    }
    setError(undefined);
    setPrivateRoomId(undefined);
  };
  const endCall = useCallback(async () => {
    resetPublicRoom();
    await sendMessage('Cancel', `${connectingAgentName.current}(Agent)`);
    setIsConnectAgent(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessage]);

  const onGeoLocationSuccess = useCallback((position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    userLatitude.current = latitude;
    userLongitude.current = longitude;
  }, []);

  const onGeoLocationError = useCallback(() => {
    userLatitude.current = null;
    userLongitude.current = null;
  }, []);

  const getLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        onGeoLocationSuccess,
        onGeoLocationError
      );
    } else {
      console.error('Geo location not available');
    }
  }, [onGeoLocationError, onGeoLocationSuccess]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  const getParticipant = useCallback(
    (identity: string) => {
      return room.getParticipantByIdentity(identity);
    },
    [room]
  );

  function getBrowserEngine() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Chrome') || userAgent.includes('Edg/')) {
      return 'Blink'; // Chrome, Edge, and Chromium-based browsers use Blink
    } else if (userAgent.includes('Firefox')) {
      return 'Gecko'; // Firefox uses Gecko
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      return 'WebKit'; // Safari uses WebKit
    } else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
      return 'Trident'; // Older versions of IE use Trident
    } else {
      return 'Unknown';
    }
  }

  const updateMetaData = useCallback(async () => {
    const embedId = room.localParticipant.identity;
    const res = await LiveKitService.getMetaDetailsFormMachine({
      lat: (userLatitude.current || 0).toString(),
      lon: (userLongitude.current || 0).toString(),
    });

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      window.matchMedia('(max-width: 767px)').matches ||
      'ontouchstart' in window ||
      navigator.maxTouchPoints;

    const currentMeta = {
      id: meta?.id ?? uniqueId,
      role: meta?.role || 'USER',
      browser: meta?.browser || fnBrowserDetect(),
      system: meta?.system || detectMob(),
      ipAddress: meta?.ipAddress || location.host,
      language: meta?.language || navigator.language,
      platform: meta?.platform || (navigator as any)?.userAgentData?.platform,
      location: meta?.location || res?.location || '',
      time: meta?.time || Date.now(),
      isMobile: meta?.isMobile || isMobile,
      participant: {
        sid: getParticipant(embedId)?.sid,
        name: getParticipant(embedId)?.name,
        participantInfo: (getParticipant(embedId) as any).participantInfo,
        roomOptions: (getParticipant(embedId) as any)?.roomOptions,
      },
      name: meta?.name,
      email: meta?.email,
      phoneNumber: meta?.phoneNumber,
      image: meta?.image,
      userAgent: navigator.userAgent,
      appVersion: navigator.appVersion,
      screen: `${screen.width} x ${screen.height}`,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      engine: getBrowserEngine(),
      window: `${window.innerWidth} x ${window.innerHeight}`,
    };

    const data = JSON.stringify(currentMeta);
    if (room.state !== 'disconnected' && isActive && account?.is_active) {
      await LiveKitService.updateParticipantMeta({
        roomName: room.name,
        participantIdentity: room.localParticipant.identity,
        metaData: encrypt(data, room.name),
      });
    }
    setMeta(encrypt(data, room.name));
  }, [
    room.localParticipant.identity,
    room.state,
    room.name,
    meta,
    uniqueId,
    getParticipant,
    isActive,
    account,
  ]);

  const fnBrowserDetect = () => {
    const userAgent = navigator.userAgent;
    if (/edg/i.test(userAgent)) return 'Edge';
    if (/chrome|chromium|crios/i.test(userAgent)) return 'Chrome';
    if (/firefox|fxios/i.test(userAgent)) return 'Firefox';
    if (/safari/i.test(userAgent)) return 'Safari';
    if (/opr\//i.test(userAgent)) return 'Opera';
    return 'No browser detection';
  };
  const detectMob = () => {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) return 'Android';
    if (/BlackBerry/i.test(userAgent)) return 'BlackBerry';
    if (/iPhone|iPad|iPod/i.test(userAgent)) return 'iOS';
    if (/Opera Mini/i.test(userAgent)) return 'Opera';
    if (/IEMobile/i.test(userAgent) || /WPDesktop/i.test(userAgent))
      return 'Windows';
    if (/Macintosh/i.test(userAgent)) return 'Macintosh';
    return 'No OS detection';
  };

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(undefined);
        setShowOverlay(false);
      }, 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  useEffect(() => {
    if (
      room.localParticipant.identity &&
      room['roomInfo']?.name &&
      room?.state === 'connected'
    ) {
      updateMetaData();
    }
  }, [
    room,
    updateMetaData,
    room.localParticipant.identity,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    room['roomInfo']?.name,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (room) {
        setActive(room.state === 'connected');
        if (room.state === 'connected') clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      if (callIntervalRef.current) clearInterval(callIntervalRef.current);
    };
  }, [room]);

  const joinRoom = async (getPrivateRoomId?: string) => {
    resetPublicRoom();
    const targetRoomId = privateRoomId || getPrivateRoomId;
    if (targetRoomId) {
      const res = await LiveKitService.getSession(
        targetRoomId,
        room.name?.split('_')[0]
      );
      console.info('session response', res.data);
      if (res?.data?.status === 'COMPLETED') {
        sessionStorage.removeItem('privateRoomId');
        sessionStorage.removeItem('remoteControl');
        localStorage.removeItem('callStartTime');
        setPrivateRoomIdJoined(false);
        setActionType(undefined);
        setPrivateRoomId(undefined);
        return;
      }
    }
    if (room?.state === ConnectionState.Connected) {
      await room.disconnect();
    } else {
      setShowOverlay(true);
    }
    console.info('room?.state', room?.state);
    if (room?.state === 'disconnected' && targetRoomId) {
      sendMessage('Join');
      sessionStorage.setItem('privateRoomId', targetRoomId);
      const participantName =
        meta?.name ||
        generateUniqueId({ length: 10, useLetters: true, useNumbers: false });

      try {
        console.info('targetRoomId check room name ', targetRoomId);
        const resData = await connectAgent({
          participantName,
          roomName: targetRoomId,
        });
        console.info('resData?.length', resData);
        console.info('resData?.length && socketUrl', socketUrl);

        if (resData?.length && socketUrl) {
          setPrivateToken(resData);
          setPrivateRoomIdJoined(true);
        }
      } catch (error) {
        console.error('Error connecting to LiveKit:', error);
      }
    }

    resetConnectionState();
    clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    console.info('privateToken value is changed', privateToken);
  }, [privateToken]);

  const disconnectRoom = async () => {
    if (room?.state === 'disconnected') {
      sessionStorage.removeItem('privateRoomId');
      sessionStorage.removeItem('remoteControl');
      localStorage.removeItem('callStartTime');
      setPrivateRoomIdJoined(false);
      setActionType(undefined);
      setPrivateRoomId(undefined);
      const uniqueSessionId = sessionStorage.getItem('uniqueSessionId');
      if (uniqueSessionId) {
        const res = await connectAgent({
          participantName: uniqueSessionId,
          roomName: room.name,
        });
        if (res?.length) {
          sessionStorage.removeItem('privateRoomId');
          sessionStorage.removeItem('remoteControl');
          localStorage.removeItem('callStartTime');
          await room.connect(REACT_APP_LIVEKIT_SOCKET_URL, res);
        }
      }
    }
  };

  const resetPublicRoom = () => {
    sessionStorage.removeItem('incomingSessionId');
    setIsCallActive(false);
    if (callIntervalRef.current) {
      clearInterval(callIntervalRef.current);
    }
    if (rejectTimeout) {
      clearInterval(rejectTimeout);
    }
    if (JoinTimeoutRef) {
      JoinTimeoutRef.current = undefined;
    }
    sound.stop();
  };

  useEffect(() => {
    const handleOffline = () => {
      setIsOfflineString('You are offline, check your internet connection');
    };

    const handleOnline = () => {
      setIsOfflineString('');
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const hidePopOver = () => {
    if ((account?.meta?.end_user as any)?.botHide) {
      setShowOverlay(false);
    }
  };

  const ChatBotPopover = () => (
    <Container isPrivateRoomJoined={isPrivateRoomJoined}>
      <FlexColumnDiv>
        {isOfflineString ? (
          <FlexColumnDiv>
            <ErrorText>{isOfflineString}</ErrorText>
          </FlexColumnDiv>
        ) : isPrivateRoomJoined ? (
          <LiveKitRoom
            serverUrl={socketUrl}
            token={privateToken}
            connect={true}
            audio={false}
            video={false}
            data-lk-theme="default"
            style={{
              borderRadius: '8px',
              border: '2px solid #0d6efd',
            }}
          >
            <PrivateRoom
              publicRoom={room}
              meta={meta}
              setPrivateRoomIdJoined={(joined) => {
                setPrivateRoomIdJoined(joined);
                setTimeout(
                  () => draggablePopoverRef.current?.resetToAnchor(),
                  250
                );
              }}
              setActionType={setActionType}
              updateMetaData={updateMetaData}
              setPrivateRoomId={setPrivateRoomId}
              setError={setError}
              hidePopOver={hidePopOver}
              account={account}
            />
          </LiveKitRoom>
        ) : !(isActive && account?.is_active) ? (
          <ErrorText>
            Something went wrong, please try after some time
          </ErrorText>
        ) : (
          <FlexColumnDiv>
            {!(
              actionType !== undefined ||
              !isConnectAgent ||
              !!error?.length ||
              room.state === ConnectionState.Disconnected
            ) && (
              <FlexColumnDiv>
                <AgentConnectTitle>
                  You are requesting to initiate a <br /> cobrowse session
                </AgentConnectTitle>
                <StyledButton
                  type="button"
                  variant={'primary'}
                  onClick={sendRequest}
                >
                  {!isConnectAgent ? 'connecting ... ' : 'Connect with agent'}
                </StyledButton>
                <AgentConnectTitle>
                  By connecting, you agree to your <br /> tab being visible to
                  agent and this session <br /> recorded. See our privacy policy
                  for more.
                </AgentConnectTitle>
                <CobrowsingInfo>
                  <CobrowsingImage
                    src={
                      'https://skygram-utils.s3.us-west-004.backblazeb2.com/logo/sm-logo.png'
                    }
                    alt="logo"
                  />
                  <CobrowsingText>Cobrowsing powered by Skygram</CobrowsingText>
                </CobrowsingInfo>
              </FlexColumnDiv>
            )}
            {isCallActive && (
              <FlexColumnDiv>
                <ErrorText>Connecting...</ErrorText>
                <StyledButton
                  type="button"
                  variant="danger"
                  onClick={() => endCall()}
                >
                  <HiPhoneMissedCall />
                </StyledButton>
              </FlexColumnDiv>
            )}
            {error && actionType !== 'Join' && error?.includes('Thank you') ? (
              <SuccessText>{error}</SuccessText>
            ) : (
              error && actionType !== 'Join' && <ErrorText>{error}</ErrorText>
            )}

            {actionType === 'Join' && (
              <JoinContainer>
                <FlexContainer>
                  <ColumnContainer>
                    <CallingHeader>
                      <Title>{'<XX> Agent'}</Title>{' '}
                      <Subtitle>
                        is asking to initiate <br />a cobrowse session
                      </Subtitle>
                    </CallingHeader>
                    <WarningText>
                      <MdOutlineTimer />{' '}
                      <CountDown
                        initialCountdown={
                          JoinTimeoutRef.current ||
                          REACT_APP_CALL_JOIN_TIMEOUT_SEC
                        }
                        onCountdownEnd={() => {
                          handleRejectRequest();
                          // Set action type to undefined
                        }}
                        interval={rejectTimeout}
                      />
                    </WarningText>
                    <ButtonWrapper>
                      <ActionButton
                        variant="danger"
                        onClick={() => {
                          sound.stop();
                          handleRejectRequest();
                        }}
                      >
                        Decline
                      </ActionButton>
                      <ActionButton
                        variant="success"
                        onClick={() => {
                          sound.stop();
                          joinRoom();
                        }}
                      >
                        Accept
                      </ActionButton>
                    </ButtonWrapper>
                    <Subtitle>
                      By connecting, you agree to your <br /> tab being visible
                      to agent and this session <br /> recorded. See our privacy
                      policy for more.
                    </Subtitle>
                    <LogoContainer>
                      <Logo
                        src="https://skygram-utils.s3.us-west-004.backblazeb2.com/logo/sm-logo.png"
                        alt="logo"
                      />
                      <LogoText>Cobrowsing powered by Skygram</LogoText>
                    </LogoContainer>
                  </ColumnContainer>
                </FlexContainer>
              </JoinContainer>
            )}
          </FlexColumnDiv>
        )}
      </FlexColumnDiv>
    </Container>
  );

  const bgColor = () => {
    return room.state === ConnectionState.Disconnected && !isPrivateRoomJoined
      ? (account?.meta?.end_user as any)?.botInActivebgColor || '#f8f9fa'
      : isActive && account?.is_active
        ? (account?.meta?.end_user as any)?.botActivebgColor || '#2f55d4'
        : '';
  };

  return isActive ? (
    <ChatRequestContainer>
      <>
        <ChatDraggablePopover
          draggablePopoverRef={draggablePopoverRef}
          isPrivateRoomJoined={isPrivateRoomJoined}
          showOverlay={showOverlay}
          floatingChatBtnRef={floatingChatBtnRef}
        >
          <ChatBotPopover />
        </ChatDraggablePopover>
        {!(account?.meta?.end_user as any)?.botHide
          ? account?.is_active && (
              <FloatingChatButton
                ref={floatingChatBtnRef}
                isDisabled={isPrivateRoomJoined}
                onClick={() => {
                  disconnectRoom();
                  setShowOverlay(!showOverlay);
                }}
                bgColor={bgColor()}
                botIconUrl={
                  (account?.meta?.end_user as any)?.botIcon ||
                  'https://f004.backblazeb2.com/file/skygram-utils/logo/skygram-logo-white.png'
                }
              />
            )
          : ''}
      </>
    </ChatRequestContainer>
  ) : null;
}
