import { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

const ChatBodyElement = styled.div`
  background-color: white;
  max-height: 65vh;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 2px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ChatBody = forwardRef<HTMLDivElement, { children: ReactNode }>(
  ({ children }, ref) => {
    return <ChatBodyElement ref={ref}>{children}</ChatBodyElement>;
  }
);

export default ChatBody;
