export const REACT_APP_USER_BASIC_URL = 'https://api.skygram.co/user';
export const REACT_APP_LIVEKIT_BASIC_URL = 'https://api.skygram.co/livekit';
export const REACT_APP_LIVEKIT_SOCKET_URL = 'wss://livekit.skygram.co';
export const bufferSec = 1000;
export const REACT_APP_CALL_JOIN_TIMEOUT_SEC = 60;

export const dummyObject = {
  items: [
    {
      orderID: '0000001211',
      orderInvoiceNo: '1234567',
      OrderBlocks: [
        {
          lineNo: [1, 4, 6, 8, 9, 1, 4],
          ProductCode: '#31451',
        },
        {
          lineNo: 2,
          ProductCode: '#64311',
        },
        {
          lineNo: 3,
          ProductCode: '#85959',
        },
      ],
    },
    {
      orderID: '0000001212',
      orderInvoiceNo: '1234568',
      OrderBlocks: [
        {
          lineNo: 7,
          ProductCode: '#86869',
        },
        {
          lineNo: [6, 7, 4, 8, 4, 2],
          ProductCode: '#10384',
        },
        {
          lineNo: 12,
          ProductCode: '#00873',
        },
      ],
    },
    {
      orderID: '0000001213',
      orderInvoiceNo: '1234569',
      OrderBlocks: [
        {
          lineNo: 76,
          ProductCode: '#22291',
        },
      ],
    },
  ],
};
